import { UUID } from "crypto";
import { ApiCaller } from "./ApiCaller";
import { SignupForm } from "../pages/SignUpPage/SignUpPage";

export interface Member {
    active: boolean;
    member_id: UUID;
    customer_id: UUID;
    parent_id: UUID;
    first_name: string;
    last_name: string;
    email: string;
    how_heard: string;
    display_id: string;
    verified_on: string;
    created_at: Date;
    updated_at: Date;
    first_membership_purchased_at: string;
    allow_text_messages: boolean;
    phone_number?: string;
    password?: string; // Hashed password
    auth_code?: string; // 6 digit 2FA code
    authenticated_last?: string;
    default_payment_method?: string;
    address: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
    } | null;
}

export class MemberApi {
    private static apiCaller = new ApiCaller("members");
    private static orderApiCaller = new ApiCaller("orders");

    public static GetOneMember(member_id: UUID) {
        return this.apiCaller.GET<Member>(`${member_id}`);
    }

    public static GetGenealogyTree(member_id: UUID) {
        return this.apiCaller.GET(`/genealogy/${member_id}`);
    }

    public static PatchOneMember<T>(member_id: UUID, dataToPatch: T) {
        return this.apiCaller.PATCH(`${member_id}`, dataToPatch);
    }

    public static MemberAlreadyExists(email: string) {
        return this.apiCaller.GET(`memberAlreadyExists/${email}`);
    }

    public static OrderHistory(email: string, limit: number, offset: number) {
        return this.orderApiCaller.GET<{ success: boolean; message?: string; results: any[]; resultCount: number }>(
            `?email=${email}&includeOrderItems=true&offset=${offset}&limit=${limit}&orderBy=ordered_on desc`
        );
    }

    public static GetOneOrderHistory(order_id: string) {
        return this.apiCaller.GET<{ success: boolean; message?: string; results: any[] }>(`oneOrderHistory/${order_id}`);
    }
}
