import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Product, ProductApi } from "../../apis/ProductApi";
import { Loader } from "../../components/Loader/Loader";
import { ProductItem } from "./ProductItem/ProductItem";
import "./product-page.scss";
import { MembershipInfoOffCanvas } from "../../components/MembershipInfoOffCanvas/MembershipInfoOffCanvas";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { BootstrapInput } from "../../components/BootstrapInput/BootstrapInput";
import { useAuthenticatedUser } from "../../context/AuthContext";

interface ProductPageProps {}

export const ProductPage = ({}: ProductPageProps) => {
    const { allProducts } = useShoppingCart();
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [membershipInfoOffCanvasShow, setMembershipInfoOffCanvasShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const { authMember } = useAuthenticatedUser();

    useEffect(() => {
        if (allProducts.length > 0) {
            setTimeout(() => {
                setLoadingProducts(false);
            }, 500);
        }
    }, [allProducts]);

    return (
        <>
            {loadingProducts ? (
                <Loader />
            ) : (
                <Container>
                    <Row>
                        <Col>
                            <div className="mt-4 fs-4 fw-bold">Product Catalog</div>
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col className="col-12" md={5}>
                            <BootstrapInput
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                label="Search"
                                placeholder="Type in a product name"
                            />
                        </Col>
                    </Row>

                    <div className="card-container row g-4 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-6">
                        {allProducts
                            .filter((product) => {
                                if (searchTerm.length === 0) {
                                    if(product.id === "prod_RKnFDT2FYYW9wE" || product.id === "prod_RKnDK7U7sbY6ye") {
                                        if(authMember && authMember.first_membership_purchased_at !== null) {
                                            return product;
                                        }
                                    } else {
                                        return product;
                                    }
                                } else {
                                    if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return product;
                                    }
                                }
                            })
                            .sort((a, b) => {
                                if (!a.is_coming_soon && b.is_coming_soon) return -1;
                                if (a.is_coming_soon && !b.is_coming_soon) return 1;
                                return 0;
                            })
                            .map((product) => (
                                <ProductItem setMembershipInfoOffCanvasShow={setMembershipInfoOffCanvasShow} key={product.id} product={product} />
                            ))}
                    </div>
                </Container>
            )}
            <MembershipInfoOffCanvas
                membershipInfoOffCanvasShow={membershipInfoOffCanvasShow}
                setMembershipInfoOffCanvasShow={setMembershipInfoOffCanvasShow}
            />
        </>
    );
};
